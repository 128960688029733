import { Center, Heading, Stack, Text } from "@chakra-ui/react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import * as ga from "../lib/ga";

const logUrl = (url) =>
  ga.event({
    action: "404",
    params: {
      url: url,
    },
  });

export default function Custom404() {
  const router = useRouter();

  useEffect(() => {
    logUrl(router.asPath);
  }, [router.asPath]);

  return (
    <>
      <Head>
        <title>404</title>
      </Head>
      <Center h={"50vh"}>
        <Stack textAlign={"center"}>
          <Heading as="h1">404 - Page Not Found</Heading>
          <Text>We couldn't find this page, or something's gone wrong</Text>
        </Stack>
      </Center>
    </>
  );
}
